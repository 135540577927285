<template>
  <v-container class="full-height" fluid>
    <v-container v-if="loadings.loadingShoppingCart" fluid>
      <!-- Skeleton -->
      <v-container class="v-container-fluid" fluid>
        <v-row>
          <v-col cols="12" md="12">
            <v-skeleton-loader
              type="table"
              :loading="loadings.loadingShoppingCart"
              rows="5"
              cols="8"
            ></v-skeleton-loader>
          </v-col>
        </v-row>
      </v-container>
    </v-container>
    <v-container v-else fluid>
      <v-container
        v-if="isRegisterEfex"
        class="v-container-fluid full-width"
        fluid
      >
        <v-container class="v-container-fluid full-width" fluid>
          <v-container class="my-container">
            <v-row>
              <v-col cols="12">
                <div class="greeting">
                  <h1>¡Hola, Juan!</h1>
                  <p>Paga tus facturas y añade fondos cuando quieras.</p>
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="6">
                <v-card class="exchange-rate-card">
                  <div class="exchange-rate-content">
                    <span class="exchange-rate-title"
                      >Tipo de cambio actual</span
                    >
                    <span class="exchange-rate-value">
                      {{ actualCurrency }} MXN
                    </span>
                    <span class="exchange-rate-bonus">Bolsa acumulada</span>
                  </div>
                  <!-- <v-card-actions>
                    <v-btn color="yellow darken-1">Actualizar</v-btn>
                  </v-card-actions> -->
                </v-card>
              </v-col>
              <v-col cols="12" md="3">
                <v-card class="balance-card">
                  <v-card-title>USD Balance</v-card-title>
                  <v-card-text> {{ balanceUsd }} USD</v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12" md="3">
                <v-card class="balance-card">
                  <v-card-title>MXN Balance</v-card-title>
                  <v-card-text>
                    <div class="price-info">
                      <span> {{ balanceMxn }} MXN</span>
                    </div>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
          <template>
            <!-- edit modal -->
            <v-dialog
              persistent
              v-model="dialogEditProfileActive"
              :width="screenWidth + '%'"
            >
              <div class="container-dialog">
                <close-dialog-component @closeDialog="closeDialog" />
                <p class="text-title mon-bold mt-2">
                  {{ texts.modalForm.textTitle }}
                </p>
                <div class="mt-9">
                  <v-col>
                    <v-row cols="12" md="6">
                      <v-text-field
                        v-model="editableData.dPurchaseDate"
                        type="text"
                        :label="texts.modalForm.purchaseDate"
                        outlined
                        color="#000000"
                        dense
                        class="global-inputs mon-regular mb-5 mr-5"
                        :placeholder="texts.modalForm.purchaseDate"
                        persistent-placeholder
                      ></v-text-field>

                      <v-text-field
                        v-model="editableData.sProviderName"
                        type="text"
                        disabled
                        :label="texts.modalForm.provider"
                        outlined
                        color="#000000"
                        dense
                        class="global-inputs mon-regular mb-5 mr-5"
                        :placeholder="texts.modalForm.provider"
                        persistent-placeholder
                      ></v-text-field>
                    </v-row>

                    <v-row cols="12" md="6">
                      <v-text-field
                        v-model="editableData.sInvoiceNumber"
                        type="text"
                        :label="texts.modalForm.invoice"
                        outlined
                        color="#000000"
                        dense
                        class="global-inputs mon-regular mb-5 mr-5"
                        :placeholder="texts.modalForm.invoice"
                        persistent-placeholder
                      ></v-text-field>
                      <v-text-field
                        v-model="editableData.dTotalAmount"
                        type="text"
                        :label="texts.modalForm.totalAmount"
                        outlined
                        color="#000000"
                        dense
                        class="global-inputs mon-regular mb-5 mr-5"
                        :placeholder="texts.modalForm.totalAmount"
                        persistent-placeholder
                      ></v-text-field>
                    </v-row>

                    <v-row cols="12" md="6">
                      <v-text-field
                        v-model="editableData.iCreditPeriod"
                        type="text"
                        :label="texts.modalForm.creditPeriod"
                        outlined
                        color="#000000"
                        dense
                        class="global-inputs mon-regular mb-5 mr-5"
                        :placeholder="texts.modalForm.creditPeriod"
                        persistent-placeholder
                      ></v-text-field>

                      <v-text-field
                        v-model="editableData.dDueDate"
                        type="text"
                        :label="texts.modalForm.dueDate"
                        outlined
                        color="#000000"
                        dense
                        disabled
                        class="global-inputs mon-regular mb-5 mr-5"
                        :placeholder="texts.modalForm.dueDate"
                        persistent-placeholder
                      ></v-text-field>
                    </v-row>

                    <v-row cols="12" md="6">
                      <v-text-field
                        type="text"
                        v-model="editableData.dInvoiceExchangeRateProtection"
                        :label="texts.modalForm.exchangeRateProtection"
                        outlined
                        color="#000000"
                        dense
                        class="global-inputs mon-regular mb-5 mr-5"
                        :placeholder="texts.modalForm.exchangeRateProtection"
                        persistent-placeholder
                      ></v-text-field>

                      <v-text-field
                        type="text"
                        v-model="actualCurrency"
                        :label="texts.modalForm.exchangeRateToday"
                        outlined
                        disabled
                        color="#000000"
                        dense
                        class="global-inputs mon-regular mb-5 mr-5"
                        :placeholder="texts.modalForm.exchangeRateToday"
                        persistent-placeholder
                      ></v-text-field>
                    </v-row>

                    <v-row cols="12" md="6">
                      <v-text-field
                        v-model="editableData.dTotalProtectedUsd"
                        type="text"
                        :label="texts.modalForm.totalProtectedUsd"
                        outlined
                        color="#000000"
                        dense
                        disabled
                        class="global-inputs mon-regular mb-5 mr-5"
                        :placeholder="texts.modalForm.totalProtectedUsd"
                        persistent-placeholder
                      ></v-text-field>
                      <v-text-field
                        v-model="editableData.dTotalUsdToday"
                        type="text"
                        :label="texts.modalForm.totalUsdToday"
                        outlined
                        color="#000000"
                        dense
                        disabled
                        class="global-inputs mon-regular mb-5 mr-5"
                        :placeholder="texts.modalForm.totalUsdToday"
                        persistent-placeholder
                      ></v-text-field>
                    </v-row>

                    <v-row cols="12" md="6">
                      <v-text-field
                        type="text"
                        v-model="editableData.dCurrencyProfitPercentage"
                        :label="texts.modalForm.currencyProfit"
                        outlined
                        color="#000000"
                        dense
                        disabled
                        class="global-inputs mon-regular mb-5 mr-5"
                        :placeholder="texts.modalForm.currencyProfit"
                        persistent-placeholder
                      ></v-text-field>

                      <v-text-field
                        type="text"
                        v-model="editableData.dCurrencyGain"
                        :label="texts.modalForm.currencyProfitUsd"
                        outlined
                        color="#000000"
                        dense
                        disabled
                        class="global-inputs mon-regular mb-5 mr-5"
                        :placeholder="texts.modalForm.currencyProfitUsd"
                        persistent-placeholder
                      ></v-text-field>
                    </v-row>

                    <v-row cols="6" md="6">
                      <v-text-field
                        type="text"
                        v-model="editableData.adjust"
                        :label="texts.modalForm.adjust"
                        outlined
                        color="#000000"
                        dense
                        class="global-inputs mon-regular mb-5 mr-5"
                        :placeholder="texts.modalForm.adjust"
                        persistent-placeholder
                      ></v-text-field>
                    </v-row>
                  </v-col>
                </div>
                <div v-if="isResponsive" class="mt-2">
                  <v-btn
                    elevation="0"
                    @click="updateExchangeRate"
                    :loading="bLoading"
                    :disabled="!validateForm"
                    class="button-primary mon-bold"
                    style="width: 100%"
                  >
                    {{ texts.textTitle }}
                  </v-btn>
                  <v-btn
                    elevation="0"
                    @click="closeDialog"
                    class="button-secondary mon-regular mt-5 mb-2"
                    style="width: 100%"
                  >
                    {{ texts.modalForm.cancel }}
                  </v-btn>
                </div>
                <div v-else class="display-flex align-items-center mt-2">
                  <v-btn
                    elevation="0"
                    @click="closeDialog"
                    class="button-cancel mon-regular"
                  >
                    {{ texts.modalForm.cancel }}
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn
                    elevation="0"
                    @click="updateExchangeRate"
                    :loading="bLoading"
                    :disabled="!validateForm"
                    class="button-save mon-bold"
                  >
                    {{ texts.textTitle }}
                  </v-btn>
                </div>
              </div>
            </v-dialog>

            <!-- create modal -->
            <v-dialog
              persistent
              v-model="dialogCreate"
              :width="screenWidth + '%'"
            >
              <div class="container-dialog">
                <close-dialog-component @closeDialog="closeModalCreate()" />
                <p class="text-title mon-bold mt-2">
                  {{ texts.textTitleCreate }}
                </p>
                <div class="mt-9">
                  <v-col>
                    <v-row cols="12" md="6">
                      <v-text-field
                        v-model="editableData.dPurchaseDate"
                        type="date"
                        :label="texts.modalForm.purchaseDate"
                        outlined
                        color="#000000"
                        dense
                        class="global-inputs mon-regular mb-5 mr-5"
                        :placeholder="texts.modalForm.purchaseDate"
                        persistent-placeholder
                      ></v-text-field>
                      <v-select
                        v-model="editableData.sProvider"
                        :items="contacts"
                        item-text="name"
                        item-value="id"
                        :label="texts.modalForm.provider"
                        outlined
                        color="#000000"
                        dense
                        class="global-inputs mon-regular mb-5 mr-5"
                        :placeholder="texts.modalForm.provider"
                        persistent-placeholder
                      ></v-select>
                    </v-row>

                    <!-- If showCreateProvider is true, show this inputs -->
                    <div v-if="showCreateProvider">
                      <v-row cols="12" md="6">
                        <v-text-field
                          v-model="contactData.sName"
                          type="text"
                          :label="texts.registerProvider.name"
                          outlined
                          color="#000000"
                          dense
                          class="global-inputs mon-regular mb-5 mr-5"
                          :placeholder="texts.registerProvider.name"
                          persistent-placeholder
                        ></v-text-field>

                        <v-text-field
                          v-model="contactData.sEmail"
                          type="text"
                          :label="texts.registerProvider.email"
                          outlined
                          color="#000000"
                          dense
                          class="global-inputs mon-regular mb-5 mr-5"
                          :placeholder="texts.registerProvider.email"
                          persistent-placeholder
                        ></v-text-field>
                      </v-row>

                      <v-row cols="12" md="6">
                        <v-text-field
                          v-model="contactData.sBank_name"
                          type="text"
                          :label="texts.registerProvider.bank_name"
                          outlined
                          color="#000000"
                          dense
                          class="global-inputs mon-regular mb-5 mr-5"
                          :placeholder="texts.registerProvider.bank_name"
                          persistent-placeholder
                        ></v-text-field>

                        <v-text-field
                          v-model="contactData.sAccount_number"
                          type="text"
                          :label="texts.registerProvider.clabe"
                          outlined
                          color="#000000"
                          dense
                          class="global-inputs mon-regular mb-5 mr-5"
                          :placeholder="texts.registerProvider.clabe"
                          persistent-placeholder
                        ></v-text-field>
                      </v-row>
                    </div>

                    <v-row cols="12" md="6">
                      <v-text-field
                        v-model="editableData.sInvoiceNumber"
                        type="text"
                        :label="texts.modalForm.invoice"
                        outlined
                        color="#000000"
                        dense
                        class="global-inputs mon-regular mb-5 mr-5"
                        :placeholder="texts.modalForm.invoice"
                        persistent-placeholder
                      ></v-text-field>
                      <v-text-field
                        v-model="editableData.dTotalAmount"
                        type="number"
                        :label="texts.modalForm.totalAmount"
                        outlined
                        color="#000000"
                        dense
                        class="global-inputs mon-regular mb-5 mr-5"
                        :placeholder="texts.modalForm.totalAmount"
                        persistent-placeholder
                      ></v-text-field>
                    </v-row>

                    <v-row cols="12" md="6">
                      <v-text-field
                        v-model="editableData.iCreditPeriod"
                        type="number"
                        :label="texts.modalForm.creditPeriod"
                        outlined
                        color="#000000"
                        dense
                        class="global-inputs mon-regular mb-5 mr-5"
                        :placeholder="texts.modalForm.creditPeriod"
                        persistent-placeholder
                      ></v-text-field>

                      <v-text-field
                        type="number"
                        v-model="editableData.dInvoiceExchangeRateProtection"
                        :label="texts.modalForm.exchangeRateProtection"
                        outlined
                        color="#000000"
                        dense
                        class="global-inputs mon-regular mb-5 mr-5"
                        :placeholder="texts.modalForm.exchangeRateProtection"
                        persistent-placeholder
                      ></v-text-field>
                    </v-row>
                  </v-col>
                </div>
                <div v-if="isResponsive" class="mt-2">
                  <v-btn
                    elevation="0"
                    @click="saveChanges"
                    :loading="bLoading"
                    :disabled="!validateForm"
                    class="button-primary mon-bold"
                    style="width: 100%"
                  >
                    {{ texts.textTitleCreate }}
                  </v-btn>
                  <v-btn
                    elevation="0"
                    @click="closeModalCreate()"
                    class="button-secondary mon-regular mt-5 mb-2"
                    style="width: 100%"
                  >
                    {{ texts.modalForm.cancel }}
                  </v-btn>
                </div>
                <div v-else class="display-flex align-items-center mt-2">
                  <v-btn
                    elevation="0"
                    @click="closeModalCreate()"
                    class="button-cancel mon-regular"
                  >
                    {{ texts.modalForm.cancel }}
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn
                    elevation="0"
                    @click="saveChanges"
                    :loading="bLoading"
                    :disabled="!validateForm"
                    class="button-save mon-bold"
                  >
                    {{ texts.textTitleCreate }}
                  </v-btn>
                </div>
              </div>
            </v-dialog>

            <!-- Separte modal -->
            <v-dialog
              persistent
              v-model="dialogSeparate"
              :width="screenWidth * 2.5 + '%'"
            >
              <div class="container-dialog">
                <close-dialog-component @closeDialog="closeModalSeparate()" />
                <div
                  class="display-flex align-items-center justify-center flex-column"
                >
                  <div class="mt-1">
                    <h3 class="text-center">
                      Invoices a pagar con el tipo de cambio actual
                      {{ actualCurrency }}
                    </h3>
                  </div>

                  <!-- Tabla con los invoices seleccionados -->
                  <v-simple-table style="width: 100%">
                    <template class="full-width">
                      <thead>
                        <tr>
                          <th class="text-center">
                            {{ texts.dataTable.provider }}
                          </th>
                          <th class="text-center">
                            {{ texts.dataTable.invoice }}
                          </th>
                          <th class="text-center">
                            {{ texts.dataTable.totalAmount }}
                          </th>
                          <th class="text-center">
                            {{ texts.dataTable.exchangeRateProtection }}
                          </th>
                          <th class="text-center">
                            {{ texts.dataTable.currencyProfit }}
                          </th>
                          <th class="text-center">
                            {{ texts.dataTable.totalProtectedUsd }}
                          </th>
                          <th class="text-center">
                            {{ texts.dataTable.totalUsdToday }}
                          </th>
                          <th class="text-center">
                            {{ texts.dataTable.currencyGain }}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="exchangeRateData in itemsSelected"
                          :key="exchangeRateData.id"
                        >
                          <td class="text-center">
                            {{ exchangeRateData.sProviderName }}
                          </td>
                          <td class="text-center">
                            {{ exchangeRateData.sInvoiceNumber }}
                          </td>
                          <td class="text-center">
                            ${{ formatMoney(exchangeRateData.dTotalAmount) }}
                            MXN
                          </td>
                          <td class="text-center">
                            {{
                              exchangeRateData.dInvoiceExchangeRateProtection
                            }}
                            MXN
                          </td>
                          <td class="text-center">
                            {{ exchangeRateData.dCurrencyProfitPercentage }} %
                          </td>
                          <td class="text-center">
                            {{
                              formatMoney(exchangeRateData.dTotalProtectedUsd)
                            }}
                            USD
                          </td>
                          <td class="text-center">
                            {{ formatMoney(exchangeRateData.dTotalUsdToday) }}
                            USD
                          </td>
                          <td class="text-center">
                            {{ formatMoney(exchangeRateData.dCurrencyGain) }}
                            USD
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                  <div class="mt-1">
                    <h3>Total en USD</h3>
                  </div>

                  <div class="mt-1">
                    <!-- Suma de los totales en USD -->
                    <p>{{ formatMoney(totalUsd) }} USD</p>
                  </div>

                  <!-- <div>
                    {{ timerCount }}
                  </div> -->
                </div>

                <div v-if="isResponsive" class="mt-2">
                  <v-btn
                    elevation="0"
                    @click="sendSeparateEfex()"
                    :loading="bLoading"
                    :disabled="!validateForm"
                    class="button-primary mon-bold"
                    style="width: 100%"
                  >
                    Pay
                  </v-btn>
                  <v-btn
                    elevation="0"
                    @click="closeModalSeparate()"
                    class="button-secondary mon-regular mt-5 mb-2"
                    style="width: 100%"
                  >
                    {{ texts.modalForm.cancel }}
                  </v-btn>
                </div>
                <div v-else class="display-flex align-items-center mt-2">
                  <v-btn
                    elevation="0"
                    @click="closeModalSeparate()"
                    class="button-cancel mon-regular"
                  >
                    {{ texts.modalForm.cancel }}
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn
                    elevation="0"
                    @click="sendSeparateEfex()"
                    :loading="bLoading"
                    :disabled="!validateForm"
                    class="button-save mon-bold"
                  >
                    Pay
                  </v-btn>
                </div>
              </div>
            </v-dialog>

            <!--Pay modal-->
            <v-dialog
              persistent
              v-model="dialogPay"
              :width="screenWidth * 2.5 + '%'"
            >
              <div class="container-dialog">
                <close-dialog-component @closeDialog="closeModalPay()" />
                <div
                  class="display-flex align-items-center justify-center flex-column"
                >
                  <div class="mt-1">
                    <h3 class="text-center">
                      Invoices a pagar con el tipo de cambio actual
                      {{ actualCurrency }}
                    </h3>
                  </div>

                  <!-- Tabla con los invoices seleccionados -->
                  <v-simple-table style="width: 100%">
                    <template class="full-width">
                      <thead>
                        <tr>
                          <th class="text-center">
                            {{ texts.dataTable.provider }}
                          </th>
                          <th class="text-center">
                            {{ texts.dataTable.invoice }}
                          </th>
                          <th class="text-center">
                            {{ texts.dataTable.totalAmount }}
                          </th>
                          <th class="text-center">
                            {{ texts.dataTable.exchangeRateProtection }}
                          </th>
                          <th class="text-center">
                            {{ texts.dataTable.currencyProfit }}
                          </th>
                          <th class="text-center">
                            {{ texts.dataTable.totalProtectedUsd }}
                          </th>
                          <th class="text-center">
                            {{ texts.dataTable.totalUsdToday }}
                          </th>
                          <th class="text-center">
                            {{ texts.dataTable.currencyGain }}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="exchangeRateData in itemsSelected"
                          :key="exchangeRateData.id"
                        >
                          <td class="text-center">
                            {{ exchangeRateData.sProviderName }}
                          </td>
                          <td class="text-center">
                            {{ exchangeRateData.sInvoiceNumber }}
                          </td>
                          <td class="text-center">
                            ${{ formatMoney(exchangeRateData.dTotalAmount) }}
                            MXN
                          </td>
                          <td class="text-center">
                            {{
                              exchangeRateData.dInvoiceExchangeRateProtection
                            }}
                            MXN
                          </td>
                          <td class="text-center">
                            {{ exchangeRateData.dCurrencyProfitPercentage }} %
                          </td>
                          <td class="text-center">
                            {{
                              formatMoney(exchangeRateData.dTotalProtectedUsd)
                            }}
                            USD
                          </td>
                          <td class="text-center">
                            {{ formatMoney(exchangeRateData.dTotalUsdToday) }}
                            USD
                          </td>
                          <td class="text-center">
                            {{ formatMoney(exchangeRateData.dCurrencyGain) }}
                            USD
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                  <div class="mt-1">
                    <h3>Total en USD</h3>
                  </div>

                  <div class="mt-1">
                    <!-- Suma de los totales en USD -->
                    <p>{{ formatMoney(totalUsd) }} USD</p>
                  </div>

                  <!-- <div>
                    {{ timerCount }}
                  </div> -->
                </div>

                <div v-if="isResponsive" class="mt-2">
                  <v-btn
                    elevation="0"
                    @click="sendPayEfex()"
                    :loading="bLoading"
                    :disabled="!validateForm"
                    class="button-primary mon-bold"
                    style="width: 100%"
                  >
                    Pay
                  </v-btn>
                  <v-btn
                    elevation="0"
                    @click="closeModalPay()"
                    class="button-secondary mon-regular mt-5 mb-2"
                    style="width: 100%"
                  >
                    {{ texts.modalForm.cancel }}
                  </v-btn>
                </div>
                <div v-else class="display-flex align-items-center mt-2">
                  <v-btn
                    elevation="0"
                    @click="closeModalPay()"
                    class="button-cancel mon-regular"
                  >
                    {{ texts.modalForm.cancel }}
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn
                    elevation="0"
                    @click="sendPayEfex()"
                    :loading="bLoading"
                    :disabled="!validateForm"
                    class="button-save mon-bold"
                  >
                    Pay
                  </v-btn>
                </div>
              </div>
            </v-dialog>

            <!-- add founds modal -->
            <v-dialog persistent v-model="addFounds" :width="screenWidth + '%'">
              <div class="container-dialog">
                <close-dialog-component @closeDialog="closeModalAddFounds()" />
                <div
                  class="display-flex align-items-center justify-center flex-column"
                >
                  <div class="mt-1">
                    <h3>Add founds</h3>

                    <div class="mt-1">
                      <h5>Account number</h5>

                      <div class="pt-2">
                        <p>{{ dataAddFounds.account_number }}</p>

                        <h5>Bank name</h5>

                        <p>{{ dataAddFounds.bank_name }}</p>

                        <h5>Beneficiary</h5>

                        <p>{{ dataAddFounds.beneficiary }}</p>

                        <h5>Clabe</h5>

                        <p>{{ dataAddFounds.clabe }}</p>

                        <h5>Routing code</h5>

                        <p>{{ dataAddFounds.routing_code }}</p>

                        <h5>Bank address</h5>

                        <p>{{ dataAddFounds.bank_address }}</p>
                        <!--
                        <v-btn
                          @click="addFoundsToAccount()"
                          :disabled="false"
                          depressed
                          class="button-add mon-bold ml-2"
                        >
                          Add
                        </v-btn> -->
                      </div>
                    </div>
                  </div>
                </div>

                <!-- <div v-if="isResponsive" class="mt-2">
                  <v-btn
                    elevation="0"
                    @click="saveChanges"
                    :loading="bLoading"
                    :disabled="!validateForm"
                    class="button-primary mon-bold"
                    style="width: 100%"
                  >
                    {{ texts.textTitleSepare }}
                  </v-btn>
                  <v-btn
                    elevation="0"
                    @click="closeModalAddFounds()"
                    class="button-secondary mon-regular mt-5 mb-2"
                    style="width: 100%"
                  >
                    {{ texts.modalForm.cancel }}
                  </v-btn>
                </div>
                <div v-else class="display-flex align-items-center mt-2">
                  <v-btn
                    elevation="0"
                    @click="closeModalAddFounds()"
                    class="button-cancel mon-regular"
                  >
                    {{ texts.modalForm.cancel }}
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn
                    elevation="0"
                    @click="saveChanges"
                    :loading="bLoading"
                    :disabled="!validateForm"
                    class="button-save mon-bold"
                  >
                    {{ texts.dataTable.add }}
                  </v-btn>
                </div> -->
              </div>
            </v-dialog>

            <!-- stepper -->
            <v-dialog
              persistent
              v-model="stepper"
              :width="screenWidth * 2.7 + '%'"
              :height="screenWidth * 30 + '%'"
            >
              <v-stepper v-model="e1">
                <close-dialog-component @closeDialog="closeModalStepper()" />
                <v-divider></v-divider>
                <div class="mt-1">
                  <h3 class="text-center">
                    Invoices a pagar con el tipo de cambio actual
                    {{ actualCurrency }}
                  </h3>
                </div>
                <v-stepper-header>
                  <v-stepper-step :complete="e1 > 1" step="1">
                    Detalle
                  </v-stepper-step>

                  <v-divider></v-divider>

                  <v-stepper-step :complete="e1 > 2" step="2">
                    Tipo de pago
                  </v-stepper-step>
                </v-stepper-header>

                <v-stepper-items>
                  <v-stepper-content step="1">
                    <!-- <v-card
                      class="mb-12"
                      color="grey lighten-1"
                      height="200px"
                    ></v-card> -->
                    <div class="container-dialog">
                      <div
                        class="display-flex align-items-center justify-center flex-column"
                      >
                        <!-- Tabla con los invoices seleccionados -->
                        <v-simple-table style="width: 100%">
                          <template class="full-width">
                            <thead>
                              <tr>
                                <th class="text-center">
                                  {{ texts.dataTable.provider }}
                                </th>
                                <th class="text-center">
                                  {{ texts.dataTable.invoice }}
                                </th>
                                <th class="text-center">
                                  {{ texts.dataTable.totalAmount }}
                                </th>
                                <th class="text-center">
                                  {{ texts.dataTable.exchangeRateProtection }}
                                </th>
                                <th class="text-center">
                                  {{ texts.dataTable.currencyProfit }}
                                </th>
                                <th class="text-center">
                                  {{ texts.dataTable.totalProtectedUsd }}
                                </th>
                                <th class="text-center">
                                  {{ texts.dataTable.totalUsdToday }}
                                </th>
                                <th class="text-center">
                                  {{ texts.dataTable.currencyGain }}
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                v-for="exchangeRateData in itemsSelected"
                                :key="exchangeRateData.id"
                              >
                                <td class="text-center">
                                  {{ exchangeRateData.sProviderName }}
                                </td>
                                <td class="text-center">
                                  {{ exchangeRateData.sInvoiceNumber }}
                                </td>
                                <td class="text-center">
                                  ${{
                                    formatMoney(exchangeRateData.dTotalAmount)
                                  }}
                                  MXN
                                </td>
                                <td class="text-center">
                                  {{
                                    exchangeRateData.dInvoiceExchangeRateProtection
                                  }}
                                  MXN
                                </td>
                                <td class="text-center">
                                  {{
                                    exchangeRateData.dCurrencyProfitPercentage
                                  }}
                                  %
                                </td>
                                <td class="text-center">
                                  {{
                                    formatMoney(
                                      exchangeRateData.dTotalProtectedUsd
                                    )
                                  }}
                                  USD
                                </td>
                                <td class="text-center">
                                  {{
                                    formatMoney(exchangeRateData.dTotalUsdToday)
                                  }}
                                  USD
                                </td>
                                <td class="text-center">
                                  {{
                                    formatMoney(exchangeRateData.dCurrencyGain)
                                  }}
                                  USD
                                </td>
                              </tr>
                            </tbody>
                          </template>
                        </v-simple-table>
                        <div class="mt-1">
                          <h3>Total en USD</h3>
                        </div>

                        <div class="mt-1">
                          <!-- Suma de los totales en USD -->
                          <p>{{ formatMoney(totalUsd) }} USD</p>
                        </div>

                        <!-- <div>
                    {{ timerCount }}
                  </div> -->
                      </div>

                      <!-- <div v-if="isResponsive" class="mt-2">
                  <v-btn
                    elevation="0"
                    @click="sendPayEfex()"
                    :loading="bLoading"
                    :disabled="!validateForm"
                    class="button-primary mon-bold"
                    style="width: 100%"
                  >
                    Pay
                  </v-btn>
                  <v-btn
                    elevation="0"
                    @click="closeModalPay()"
                    class="button-secondary mon-regular mt-5 mb-2"
                    style="width: 100%"
                  >
                    {{ texts.modalForm.cancel }}
                  </v-btn>
                </div>
                <div v-else class="display-flex align-items-center mt-2">
                  <v-btn
                    elevation="0"
                    @click="closeModalPay()"
                    class="button-cancel mon-regular"
                  >
                    {{ texts.modalForm.cancel }}
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn
                    elevation="0"
                    @click="sendPayEfex()"
                    :loading="bLoading"
                    :disabled="!validateForm"
                    class="button-save mon-bold"
                  >
                    Pay
                  </v-btn>
                </div> -->
                    </div>

                    <v-btn color="primary" @click="e1 = 2"> Continue </v-btn>

                    <v-btn text> Cancel </v-btn>
                  </v-stepper-content>

                  <v-stepper-content step="2">
                    <v-card
                      class="mb-12"
                      color="grey lighten-1"
                      height="200px"
                    ></v-card>

                    <!-- <v-btn
            color="primary"
            @click="e1 = 3"
          >
            Continue
          </v-btn> -->

                    <v-btn @click="e1 = 1" text> Cancel </v-btn>
                  </v-stepper-content>
                </v-stepper-items>
              </v-stepper>
            </v-dialog>
          </template>

          <div
            class="convert-display-block display-flex align-items-center justify-start"
          >
            <div>
              <h3>Bolsa acumulada: {{ formatMoney(balance) }} USD</h3>
              <h5 style="font-weight: normal">
                Suma de las wallet en USD y MXN
              </h5>
            </div>
            <div class="ml-2">
              <v-btn
                @click="openModalAddFounds()"
                :disabled="false"
                depressed
                class="button-add mon-bold ml-2"
              >
                {{ texts.dataTable.addFounds }}
              </v-btn>
            </div>
          </div>
          <div
            class="convert-display-block display-flex align-items-center justify-end"
          >
            <!-- Btn add -->
            <div
              class="display-flex align-items-center justify-center flex-column"
            >
              <div>
                <h3>Tipo de cambio actual {{ actualCurrency }} MXN</h3>
              </div>

              <div>
                <h5 style="font-weight: normal">
                  Tipo de cambio de actualizará en:
                  {{ secondsToMinutes(contador) }}
                </h5>
              </div>
            </div>
            <v-btn
              @click="openModalCreate"
              :disabled="false"
              depressed
              class="button-add mon-bold ml-2"
            >
              {{ texts.dataTable.add }}
            </v-btn>
            <!-- <div>Bola</div> -->
          </div>

          <v-container fluid>
            <v-tabs v-model="tab" class="full-width">
              <v-tab>USD</v-tab>
              <v-tab>MXN</v-tab>
            </v-tabs>
          </v-container>

          <v-tabs-items v-model="tab" class="full-width" no-slide>
            <!-- Pestaña para USD -->
            <v-tab-item v-if="tab === 0" class="full-width d-flex flex-column">
              <!-- Tabla para USD -->
              <p>Wallet {{ balanceUsd }} USD</p>
              <v-simple-table style="width: 100%">
                <v-simple-table style="width: 100%">
                  <template class="full-width">
                    <thead>
                      <tr>
                        <th class="text-center">
                          {{ texts.dataTable.purchaseDate }}
                        </th>
                        <th class="text-center">
                          {{ texts.dataTable.provider }}
                        </th>
                        <th class="text-center">
                          {{ texts.dataTable.invoice }}
                        </th>
                        <th class="text-center">
                          {{ texts.dataTable.totalAmount }}
                        </th>
                        <th class="text-center">
                          {{ texts.dataTable.creditPeriod }}
                        </th>
                        <th class="text-center">
                          {{ texts.dataTable.dueDate }}
                        </th>
                        <th class="text-center">
                          {{ texts.dataTable.exchangeRateProtection }}
                        </th>
                        <th class="text-center">
                          {{ texts.dataTable.status }}
                        </th>
                        <th class="text-center">
                          {{ texts.dataTable.currencyProfit }}
                        </th>
                        <th class="text-center">
                          {{ texts.dataTable.totalProtectedUsd }}
                        </th>
                        <th class="text-center">
                          {{ texts.dataTable.totalUsdToday }}
                        </th>
                        <th class="text-center">
                          {{ texts.dataTable.currencyGain }}
                        </th>
                        <th class="text-center">
                          {{ texts.dataTable.actions }}
                          <v-checkbox
                            v-model="bSelectedAll"
                            :input-value="false"
                            color="#000000"
                            hide-details
                            @change="toggleAll"
                          ></v-checkbox>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="exchangeRateData in exchangeRateData"
                        :key="exchangeRateData.id"
                      >
                        <td class="text-center">
                          {{ formatDate(exchangeRateData.dPurchaseDate) }}
                        </td>
                        <td class="text-center">
                          {{ exchangeRateData.sProviderName }}
                        </td>
                        <td class="text-center">
                          {{ exchangeRateData.sInvoiceNumber }}
                        </td>
                        <td class="text-center">
                          ${{ formatMoney(exchangeRateData.dTotalAmount) }} MXN
                        </td>
                        <td class="text-center">
                          {{ exchangeRateData.iCreditPeriod }} días
                        </td>
                        <td class="text-center">
                          {{ formatDate(exchangeRateData.dDueDate) }}
                        </td>
                        <td class="text-center">
                          {{ exchangeRateData.dInvoiceExchangeRateProtection }}
                          MXN
                        </td>
                        <td class="text-center">
                          {{
                            texts.status[exchangeRateData.statusName] ||
                            exchangeRateData.statusName
                          }}
                        </td>

                        <td class="text-center">
                          {{ exchangeRateData.dCurrencyProfitPercentage }} %
                        </td>
                        <td class="text-center">
                          {{ formatMoney(exchangeRateData.dTotalProtectedUsd) }}
                          USD
                        </td>
                        <td class="text-center">
                          {{ formatMoney(exchangeRateData.dTotalUsdToday) }} USD
                        </td>
                        <td class="text-center">
                          {{ formatMoney(exchangeRateData.dCurrencyGain) }} USD
                        </td>
                        <td class="text-center">
                          <div
                            class="display-flex align-items-center justify-center"
                          >
                            <v-checkbox
                              v-model="exchangeRateData.bSelected"
                              :disabled="
                                exchangeRateData.statusName ===
                                'WAITING_FOR_DEPOSIT'
                              "
                              :input-value="false"
                              color="#000000"
                              hide-details
                            ></v-checkbox>
                            <div class="ml-4">
                              <v-btn
                                color="primary"
                                :disabled="
                                  exchangeRateData.statusName ===
                                  'WAITING_FOR_DEPOSIT'
                                "
                                @click="openModal(exchangeRateData)"
                              >
                                {{ texts.dataTable.edit }}
                              </v-btn>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-simple-table>
              <div class="display-flex align-items-center justify-end pb-5">
                <!-- <v-btn
                  @click="openModalForwards"
                  :disabled="true"
                  depressed
                  class="button-add mon-bold ml-2"
                >
                  Derivados
                </v-btn>

                <v-btn
                  @click="openModalSeparate"
                  :disabled="true"
                  depressed
                  class="button-add mon-bold ml-2"
                >
                  Separar
                </v-btn>

                <v-btn
                  @click="openDeferredPayment"
                  :disabled="true"
                  depressed
                  class="button-add mon-bold ml-2"
                >
                  Deferred Payment
                </v-btn> -->

                <v-btn
                  @click="sendToPayNow"
                  :disabled="false"
                  depressed
                  class="button-add mon-bold ml-2"
                >
                  Pagar
                </v-btn>
              </div>
            </v-tab-item>

            <!-- Pestaña para MXN -->
            <v-tab-item v-if="tab === 1" class="full-width d-flex flex-column">
              <p>Wallet {{ balanceMxn }} MXN</p>
              <v-simple-table style="width: 100%">
                <v-simple-table style="width: 100%">
                  <template class="full-width">
                    <thead>
                      <tr>
                        <th class="text-center">
                          {{ texts.dataTable.purchaseDate }}
                        </th>
                        <th class="text-center">
                          {{ texts.dataTable.provider }}
                        </th>
                        <th class="text-center">
                          {{ texts.dataTable.invoice }}
                        </th>
                        <th class="text-center">
                          {{ texts.dataTable.totalAmount }}
                        </th>
                        <th class="text-center">
                          {{ texts.dataTable.creditPeriod }}
                        </th>
                        <th class="text-center">
                          {{ texts.dataTable.dueDate }}
                        </th>
                        <th class="text-center">
                          {{ texts.dataTable.exchangeRateProtection }}
                        </th>
                        <th class="text-center">
                          {{ texts.dataTable.status }}
                        </th>
                        <th class="text-center">
                          {{ texts.dataTable.currencyProfit }}
                        </th>
                        <th class="text-center">
                          {{ texts.dataTable.totalProtectedUsd }}
                        </th>
                        <th class="text-center">
                          {{ texts.dataTable.totalUsdToday }}
                        </th>
                        <th class="text-center">
                          {{ texts.dataTable.currencyGain }}
                        </th>
                        <th class="text-center">
                          {{ texts.dataTable.actions }}
                          <v-checkbox
                            v-model="bSelectedAllSeparate"
                            :input-value="false"
                            color="#000000"
                            hide-details
                            @change="toggleAllSeparated"
                          ></v-checkbox>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="exchangeRateData in exchangeRateDataSeparate"
                        :key="exchangeRateData.id"
                      >
                        <td class="text-center">
                          {{ formatDate(exchangeRateData.dPurchaseDate) }}
                        </td>
                        <td class="text-center">
                          {{ exchangeRateData.sProviderName }}
                        </td>
                        <td class="text-center">
                          {{ exchangeRateData.sInvoiceNumber }}
                        </td>
                        <td class="text-center">
                          ${{ formatMoney(exchangeRateData.dTotalAmount) }} MXN
                        </td>
                        <td class="text-center">
                          {{ exchangeRateData.iCreditPeriod }} días
                        </td>
                        <td class="text-center">
                          {{ formatDate(exchangeRateData.dDueDate) }}
                        </td>
                        <td class="text-center">
                          {{ exchangeRateData.dInvoiceExchangeRateProtection }}
                          MXN
                        </td>
                        <td class="text-center">
                          {{
                            texts.status[exchangeRateData.statusName] ||
                            exchangeRateData.statusName
                          }}
                        </td>

                        <td class="text-center">
                          {{ exchangeRateData.dCurrencyProfitPercentage }} %
                        </td>
                        <td class="text-center">
                          {{ formatMoney(exchangeRateData.dTotalProtectedUsd) }}
                          USD
                        </td>
                        <td class="text-center">
                          {{ formatMoney(exchangeRateData.dTotalUsdToday) }} USD
                        </td>
                        <td class="text-center">
                          {{ formatMoney(exchangeRateData.dCurrencyGain) }} USD
                        </td>
                        <td class="text-center">
                          <div
                            class="display-flex align-items-center justify-center"
                          >
                            <v-checkbox
                              v-model="exchangeRateData.bSelected"
                              :input-value="false"
                              color="#000000"
                              hide-details
                            ></v-checkbox>
                            <div class="ml-4">
                              <v-btn
                                color="primary"
                                disabled
                                @click="openModal(exchangeRateData)"
                              >
                                {{ texts.dataTable.edit }}
                              </v-btn>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-simple-table>
              <div class="display-flex align-items-center justify-end pb-5">
                <!-- <v-btn
                  @click="openModalCreate"
                  :disabled="true"
                  depressed
                  class="button-add mon-bold ml-2"
                >
                  Derivados
                </v-btn>

                <v-btn
                  @click="openModalSeparate"
                  :disabled="false"
                  depressed
                  class="button-add mon-bold ml-2"
                >
                  Separar
                </v-btn>

                <v-btn
                  @click="openDeferredPayment"
                  :disabled="false"
                  depressed
                  class="button-add mon-bold ml-2"
                >
                  Deferred Payment
                </v-btn> -->

                <v-btn
                  @click="sendToPayNowMxn"
                  depressed
                  class="button-add mon-bold ml-2"
                >
                  Pagar
                </v-btn>
              </div>
            </v-tab-item>
          </v-tabs-items>
        </v-container>
      </v-container>

      <div v-else class="full-width">
        <v-container class="v-container-fluid full-height full-widht" fluid>
          <div
            class="display-flex align-items-center justify-center flex-column"
          >
            <div class="mt-1">
              <h3>
                Necesitas registrarte en Efex para poder usar esta funcionalidad
              </h3>
            </div>

            <div class="mt-1">
              <h3>Formulario de Registro</h3>
            </div>

            <div class="mt-1">
              <h4>Necesitamos estos datos para poder crear una cuenta</h4>
            </div>

            <div class="pt-2 form-container">
              <!-- input solicitando el nombre del sBank_name -->
              <v-text-field
                v-model="dataEfex.sBankName"
                type="text"
                :label="texts.register.sBankName"
                outlined
                color="#000000"
                dense
                class="global-inputs mb-3"
                :placeholder="texts.register.sBankName"
                persistent-placeholder
              ></v-text-field>

              <!-- input solicitando el nombre del sAccount_number -->
              <v-text-field
                v-model="dataEfex.sAccountNumber"
                type="text"
                :label="texts.register.sAccountNumber"
                outlined
                color="#000000"
                dense
                class="global-inputs mb-3"
                :placeholder="texts.register.sAccountNumber"
                persistent-placeholder
              ></v-text-field>

              <!-- input solicitando el nombre del sRouting_number -->
              <v-text-field
                v-model="dataEfex.sRoutingNumber"
                type="text"
                :label="texts.register.sRoutingNumber"
                outlined
                color="#000000"
                dense
                class="global-inputs mb-3"
                :placeholder="texts.register.sRoutingNumber"
                persistent-placeholder
              ></v-text-field>

              <!-- <v-text-field
                v-model="dataEfex.clabe"
                type="text"
                :label="texts.register.clabe"
                outlined
                color="#000000"
                dense
                class="global-inputs mb-3"
                :placeholder="texts.register.clabe"
                persistent-placeholder
              ></v-text-field>

              <v-text-field
                v-model="dataEfex.sBankMxnAccount"
                type="text"
                :label="texts.register.sBankNameMxn"
                outlined
                color="#000000"
                dense
                class="global-inputs mb-3"
                :placeholder="texts.register.sBankNameMxn"
                persistent-placeholder
              ></v-text-field> -->

              <div
                class="display-flex align-items-center justify-center flex-column"
              >
                <v-btn
                  @click="createSubAccount(dataEfex)"
                  class="button-primary width-100 mon-bold"
                  elevation="0"
                >
                  {{ texts.register.register }}
                </v-btn>
              </div>
            </div>
          </div>
        </v-container>
      </div>

      <v-container>
        <div class="display-flex align-items-center justify-start mb-5">
          <h2 class="text-title mon-bold">
            {{ texts.status.paid }}
          </h2>
        </div>

        <h3 class="text-title mon-bold pb-5">Historial</h3>

        <v-simple-table style="width: 100%">
          <template class="full-width">
            <thead>
              <tr>
                <th class="text-center">
                  {{ texts.dataTable.purchaseDate }}
                </th>
                <th class="text-center">
                  {{ texts.dataTable.provider }}
                </th>
                <th class="text-center">{{ texts.dataTable.invoice }}</th>
                <th class="text-center">
                  {{ texts.dataTable.totalAmount }}
                </th>
                <th class="text-center">
                  {{ texts.dataTable.creditPeriod }}
                </th>
                <th class="text-center">{{ texts.dataTable.dueDate }}</th>
                <th class="text-center">
                  {{ texts.dataTable.exchangeRateProtection }}
                </th>
                <th class="text-center">
                  {{ texts.dataTable.status }}
                </th>
                <th class="text-center">
                  {{ texts.dataTable.currencyProfit }}
                </th>
                <th class="text-center">
                  {{ texts.dataTable.totalProtectedUsd }}
                </th>
                <th class="text-center">
                  {{ texts.dataTable.totalUsdToday }}
                </th>
                <th class="text-center">
                  {{ texts.dataTable.currencyGain }}
                </th>
                <th>Comprobante</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="exchangeRateData in exchangeRateDataPaid"
                :key="exchangeRateData.id"
              >
                <td class="text-center">
                  {{ formatDate(exchangeRateData.dPurchaseDate) }}
                </td>
                <td class="text-center">
                  {{ exchangeRateData.sProviderName }}
                </td>
                <td class="text-center">
                  {{ exchangeRateData.sInvoiceNumber }}
                </td>
                <td class="text-center">
                  ${{ formatMoney(exchangeRateData.dTotalAmount) }} MXN
                </td>
                <td class="text-center">
                  {{ exchangeRateData.iCreditPeriod }} días
                </td>
                <td class="text-center">
                  {{ formatDate(exchangeRateData.dDueDate) }}
                </td>
                <td class="text-center">
                  {{ exchangeRateData.dInvoiceExchangeRateProtection }}
                  MXN
                </td>
                <td class="text-center">
                  {{
                    texts.status[exchangeRateData.statusName] ||
                    exchangeRateData.statusName
                  }}
                </td>

                <td class="text-center">
                  {{ exchangeRateData.dCurrencyProfitPercentage }} %
                </td>
                <td class="text-center">
                  {{ formatMoney(exchangeRateData.dTotalProtectedUsd) }}
                  USD
                </td>
                <td class="text-center">
                  {{ formatMoney(exchangeRateData.dTotalUsdToday) }} USD
                </td>
                <td class="text-center">
                  {{ formatMoney(exchangeRateData.dCurrencyGain) }} USD
                </td>
                <td>
                  <v-btn
                    color="primary"
                    :disabled="
                      exchangeRateData.paymentExchangeRate[0]
                        .sPayment_reference === null
                    "
                    @click="openReceipt(exchangeRateData)"
                  >
                    {{ texts.dataTable.receipt }}
                  </v-btn>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-container>
    </v-container>
  </v-container>
</template>

<script>
export default {
  props: {
    texts: {
      type: Object,
      default: ""
    }
  },
  name: "AboutContentLayout",
  data() {
    return {
      loadings: {
        isLoading: false,
        loadingShoppingCart: false
      },
      items: [],
      exchangeRateData: [],
      exchangeRateDataPaid: [],
      exchangeRateDataSeparate: [],
      showModal: false,
      selectedItem: null,
      editableData: {},
      contactData: {
        sName: "",
        sEmail: "",
        sBank_name: "",
        sAccount_number: "",
        sEntity_business: "COMPANY"
      },
      dialog: false,
      dataAddFounds: {},
      screenWidth: 0,
      contador: 179,
      dialogEditProfileActive: false,
      soloNumbers: "",
      isResponsive: false,
      isLoading: false,
      bLoading: false,
      dialogCreate: false,
      actualCurrency: 0,
      referenceCurrency: "",
      isPayMxn: false,
      dialogSeparate: false,
      isDeferredPayment: false,
      timerCount: 30,
      timerId: null,
      itemsSelected: [],
      isRegisterEfex: false,
      addFounds: false,
      balance: 0,
      balanceUsd: 0,
      balanceMxn: 0,
      dataEfex: {},
      tab: 0,
      dialogPay: false,
      bSelectedAll: false,
      bSelectedAllSeparate: false,
      contacts: [],
      showCreateProvider: false,
      totalUsd: 0,
      stepper: false,
      e1: 1,
    };
  },
  beforeMount() {
    //this.getActualCurrency();
  },
  created() {
    this.getActualCurrency();
    this.iniciarContador();
    //this.getActualCurrencyEfex();
    //this.getExchangeRate();
    this.editableData = { ...this.data };
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  mounted() {},
  beforeDestroy() {
    clearInterval(this.intervalo);
  },
  methods: {
    redirect: function (url) {
      window.open(url, "blank");
    },
    iniciarContador() {
      this.intervalo = setInterval(() => {
        if (this.contador > 0) {
          this.contador--;
        } else {
          this.contador = 179;
          this.getActualCurrency();
        }
      }, 1000);
    },
    secondsToMinutes: function (seconds) {
      let minutes = Math.floor(seconds / 60);
      let secondsRest = seconds % 60;
      let secondsRestString = secondsRest.toString();
      let minutesString = minutes.toString();
      if (secondsRest < 10) {
        secondsRestString = "0" + secondsRest.toString();
      }
      if (minutes < 10) {
        minutesString = "0" + minutes.toString();
      }
      return minutesString + ":" + secondsRestString;
    },
    capitalize: function (str) {
      let lower = str.toLowerCase();
      return str.charAt(0).toUpperCase() + lower.slice("1");
    },
    formatDate: function (date) {
      let newDate = new Date(date);
      let day = newDate.getDate();
      let month = newDate.getMonth() + 1;
      let year = newDate.getFullYear();
      return `${month}/${day}/${year}`;
    },
    formatDateToSend: function (date) {
      let newDate = new Date(date);
      let day = newDate.getDate();
      let month = newDate.getMonth() + 1;
      let year = newDate.getFullYear();
      return `${year}-${month}-${day}`;
    },
    handleResize: function () {
      if (window.innerWidth >= 960) {
        this.screenWidth = 30;
      } else {
        this.screenWidth = 100;
      }

      if (window.innerWidth < 960) {
        this.isResponsive = true;
      } else {
        this.isResponsive = false;
      }
    },
    formatMoney: function (money) {
      return FORMAT_MONEY(money);
    },
    toggleAll: function (checked) {
      this.exchangeRateData.forEach((item) => {
        if (item.statusName !== "WAITING_FOR_DEPOSIT") {
          item.bSelected = checked;
        }
      });
    },
    toggleAllSeparated: function (checked) {
      this.exchangeRateDataSeparate.forEach((item) => {
        item.bSelected = checked;
      });
    },
    closeModalCreate: function () {
      this.dialogCreate = false;
      this.showCreateProvider = false;
      this.contactData = {
        sName: "",
        sEmail: "",
        sBank_name: "",
        sAccount_number: "",
        sEntity_business: "COMPANY"
      };
    },
    sendToPayNowMxn: function () {
      this.isDeferredPayment = false;
      this.isPayMxn = true;
      this.itemsSelected = this.exchangeRateDataSeparate.filter(
        (item) => item.bSelected
      );
      this.itemsSelected.forEach((item) => {
        item.dTotalUsdToday = parseFloat(item.dTotalUsdToday);
        this.totalUsd += item.dTotalUsdToday;
      });
      console.log("this.itemsSelected", this.itemsSelected);
      if (this.itemsSelected.length > 0) {
        this.dialogPay = true;
      } else {
        this.mixError("No hay elementos seleccionados", 400);
      }
    },
    sendToPayNow: function () {
      console.log("sendToPayNow");
      this.isDeferredPayment = false;
      this.sendToPay();
    },
    sendToPay: function () {
      this.itemsSelected = this.exchangeRateData.filter(
        (item) => item.bSelected
      );
      if (this.itemsSelected.length > 0) {
        this.stepper = true;
        //this.dialogPay = true;
        this.itemsSelected.forEach((item) => {
          item.dTotalUsdToday = parseFloat(item.dTotalUsdToday);
          this.totalUsd += item.dTotalUsdToday;
        });
      } else {
        this.mixError("No hay elementos seleccionados", 400);
      }
    },
    sendPayEfex: function () {
      this.bLoading = true;

      const payload = {
        pays: this.itemsSelected,
        referenceCurrency: this.referenceCurrency,
        isDeferredPayment: this.isDeferredPayment,
        isPayMxn: this.isPayMxn
      };

      console.log("payload", payload);

      DB.post(
        `${URI}/api/v1/${this.selectLanguage}/exchange-rate/sendPayment/`,
        payload,
        {
          headers: {
            Authorization: this.$store.state.sToken
              ? `Bearer ${this.$store.state.sToken}`
              : ""
          }
        }
      )
        .then((response) => {
          this.mixSuccess(response.data.message);
          this.bLoading = false;
          this.dialogPay = false;
          this.getExchangeRate();
          this.totalUsd = 0;
        })
        .catch((error) => {
          this.bLoading = false;
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    onDateChange() {
      this.editableData.dDueDate = this.editableData.dPurchaseDate;
    },
    getExchangeRate: function () {
      this.showShoppingCart = false;
      this.items = [];

      DB.get(
        `${URI}/api/v1/${this.selectLanguage}/exchange-rate/?exchangeRateToday=${this.actualCurrency}`,
        {
          headers: {
            Authorization: this.$store.state.sToken
              ? `Bearer ${this.$store.state.sToken}`
              : ""
          }
        }
      )
        .then((response) => {
          this.isRegisterEfex = response.data.registerInEfex;
          this.exchangeRateDataPaid =
            response.data.exchangeRate.exchangeRatesStatePaid;
          this.exchangeRateData =
            response.data.exchangeRate.exchangeRatesStateToPay;
          this.exchangeRateDataSeparate =
            response.data.exchangeRate.exchangeRatesStateSeparated;
          this.balanceMxn = response.data.balance.balanceMxn;
          this.balanceUsd = response.data.balance.balanceUsd;
          this.balance =
            parseFloat(this.balanceMxn) / parseFloat(this.actualCurrency) +
            parseFloat(this.balanceUsd);
          this.mixSuccess(response.data.message);
        })
        .catch((error) => {
          this.mixError(error.response.data.message, error.response.status);
        })
        .finally(() => {
          this.loadings.loadingShoppingCart = false;
          this.getContacts();
        });
    },
    openReceipt: function (item) {
      DB.get(
        `${URI}/api/v1/${this.selectLanguage}/exchange-rate/getPaymentRecipt/${item.paymentExchangeRate[0].sPayment_reference}/`,
        {
          headers: {
            Authorization: this.$store.state.sToken
              ? `Bearer ${this.$store.state.sToken}`
              : ""
          }
        }
      )
        .then((response) => {
          if (response.data && response.data.payment) {
            let base64 = response.data.payment.data;

            const base64Pattern =
              /^data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+);base64,/;
            if (base64Pattern.test(base64)) {
              base64 = base64.split(",")[1];
            }

            try {
              const binaryString = window.atob(base64);
              const bytes = new Uint8Array(binaryString.length);
              for (let i = 0; i < binaryString.length; i++) {
                bytes[i] = binaryString.charCodeAt(i);
              }
              const blob = new Blob([bytes], { type: "application/pdf" });

              const url = URL.createObjectURL(blob);
              window.open(url, "_blank");
            } catch (e) {
              console.error("Error al decodificar base64: ", e);
            }
          } else {
            console.error(
              "No se encontraron los datos del PDF en la respuesta"
            );
          }
        })
        .catch((error) => {
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    getContacts: function () {
      DB.get(`${URI}/api/v1/${this.selectLanguage}/contacts-wallet/`, {
        headers: {
          Authorization: this.$store.state.sToken
            ? `Bearer ${this.$store.state.sToken}`
            : ""
        }
      })
        .then((response) => {
          this.contacts = response.data.contacts;
          //Add last option to create new contact
          this.contacts.push({
            id: 0,
            name: "Crear nuevo contacto"
          });
        })
        .catch((error) => {
          console.log("error", error);
          //this.mixError(error.response.data.message, error.response.status);
        })
        .finally(() => {
          //this.loadings.loadingShoppingCart = false;
        });
    },
    getActualCurrency: function () {
      this.loadings.loadingShoppingCart = true;
      DB.get(`${URI}/api/v1/${this.selectLanguage}/exchange-rate-global`, {
        headers: {
          Authorization: this.$store.state.sToken
            ? `Bearer ${this.$store.state.sToken}`
            : ""
        }
      }).then((response) => {
        this.referenceCurrency = response.data.reference;
        this.actualCurrency = response.data.mxnRate;
        this.getExchangeRate();
      });
    },
    updateExchangeRate: function () {
      this.bLoading = true;
      let newdPurchaseDate = this.formatDateToSend(
        this.editableData.dPurchaseDate
      );
      const payload = {
        sExchangeRateId: this.selectedItem.sExchangeRateId,
        dPurchaseDate: newdPurchaseDate.toString(),
        sProvider: this.editableData.sProvider,
        sInvoiceNumber: this.editableData.sInvoiceNumber,
        dTotalAmount: parseFloat(this.editableData.dTotalAmount),
        iCreditPeriod: parseInt(this.editableData.iCreditPeriod),
        dInvoiceExchangeRateProtection:
          this.editableData.dInvoiceExchangeRateProtection
      };

      const config = {
        headers: {
          Authorization: `Bearer ${this.$store.state.sToken}`
        }
      };

      DB.put(
        `${URI}/api/v1/${this.selectLanguage}/exchange-rate/${this.selectedItem.sExchangeRateId}`,
        payload,
        config
      )
        .then((response) => {
          this.bLoading = false;
          this.dialogEditProfileActive = false;
          this.mixSuccess(response.data.message);
          this.getExchangeRate();
          this.closeDialog();
        })
        .catch((error) => {
          this.bLoading = false;
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    openModalAddFounds() {
      this.addFounds = true;
      const payload = {
        amount: 100
      };

      DB.post(
        `${URI}/api/v1/${this.selectLanguage}/exchange-rate/addFounds`,
        payload,
        {
          headers: {
            Authorization: this.$store.state.sToken
              ? `Bearer ${this.$store.state.sToken}`
              : ""
          }
        }
      )
        .then((response) => {
          console.log("response", response.data.account);
          this.dataAddFounds = response.data.account;
          console.log("this.dataAddFounds", this.dataAddFounds);
        })
        .catch((error) => {
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    openDeferredPayment: function () {
      this.isDeferredPayment = true;
      this.sendToPay();
    },
    closeModalAddFounds() {
      this.addFounds = false;
    },
    openModal(item) {
      this.totalUsd = 0;
      this.selectedItem = item;
      this.editableData = { ...this.selectedItem };
      this.dialogEditProfileActive = true;
    },
    sendSeparateEfex: function () {
      this.bLoading = true;
      const payload = {
        exchangeRateId: this.itemsSelected,
        rateUuid: this.referenceCurrency,
        rateInt: this.actualCurrency
      };
      console.log("payload", payload);

      DB.post(
        `${URI}/api/v1/${this.selectLanguage}/exchange-rate/separateExchangeRate/`,
        payload,
        {
          headers: {
            Authorization: this.$store.state.sToken
              ? `Bearer ${this.$store.state.sToken}`
              : ""
          }
        }
      )
        .then((response) => {
          this.mixSuccess(response.data.message);
          this.bLoading = false;
          this.dialogSeparate = false;
          this.getExchangeRate();
        })
        .catch((error) => {
          this.bLoading = false;
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    openModalSeparate(item) {
      this.totalUsd = 0;
      this.itemsSelected = this.exchangeRateData.filter(
        (item) => item.bSelected
      );
      if (this.itemsSelected.length > 0) {
        this.dialogSeparate = true;
        this.itemsSelected.forEach((item) => {
          item.dTotalUsdToday = parseFloat(item.dTotalUsdToday);
          this.totalUsd += item.dTotalUsdToday;
        });
      } else {
        this.mixError("No hay elementos seleccionados", 400);
      }
    },
    openModalForwards: function () {
      this.totalUsd = 0;
      this.itemsSelected = this.exchangeRateData.filter(
        (item) => item.bSelected
      );
      if (this.itemsSelected.length > 0) {
        this.dialogSeparate = true;
        this.itemsSelected.forEach((item) => {
          item.dTotalUsdToday = parseFloat(item.dTotalUsdToday);
          this.totalUsd += item.dTotalUsdToday;
        });
      } else {
        this.mixError("No hay elementos seleccionados", 400);
      }
    },
    closeModalSeparate() {
      this.dialogSeparate = false;
      this.totalUsd = 0;
      this.stopTimer();
    },
    closeModalPay() {
      this.dialogPay = false;
      this.totalUsd = 0;
      this.stopTimer();
    },
    closeModalStepper() {
      this.stepper = false;
      this.totalUsd = 0;
      this.stopTimer();
    },
    closeDialog() {
      this.dialogEditProfileActive = false;
    },
    submitForm() {
      this.$emit("updateData", this.editableData);
      this.$emit("close");
    },
    resetForm() {
      this.editableData = {
        dPurchaseDate: "",
        sProvider: "",
        sInvoiceNumber: "",
        dTotalAmount: "",
        iCreditPeriod: "",
        dInvoiceExchangeRateProtection: ""
      };
    },
    openModalCreate() {
      this.dialogCreate = true;
      this.resetForm();
    },
    validateForm() {
      return (
        this.editableData.dPurchaseDate !== "" &&
        this.editableData.sProvider !== "" &&
        this.editableData.sInvoiceNumber !== "" &&
        this.editableData.dTotalAmount !== "" &&
        this.editableData.iCreditPeriod !== "" &&
        this.editableData.dInvoiceExchangeRateProtection !== ""
      );
    },
    async saveChanges() {
      this.bLoading = true;
      let newdPurchaseDate = this.formatDateToSend(
        this.editableData.dPurchaseDate
      );
      const payload = {
        dPurchaseDate: newdPurchaseDate.toString(),
        sProvider: this.editableData.sProvider,
        sInvoiceNumber: this.editableData.sInvoiceNumber,
        dTotalAmount: parseFloat(this.editableData.dTotalAmount),
        iCreditPeriod: parseInt(this.editableData.iCreditPeriod),
        createContact: this.showCreateProvider,
        contactData: this.contactData,
        dInvoiceExchangeRateProtection:
          this.editableData.dInvoiceExchangeRateProtection
      };

      const config = {
        headers: {
          Authorization: `Bearer ${this.$store.state.sToken}`
        }
      };

      DB.post(
        `${URI}/api/v1/${this.selectLanguage}/exchange-rate/`,
        payload,
        config
      )
        .then((response) => {
          this.bLoading = false;
          this.dialogCreate = false;
          this.mixSuccess(response.data.message);
          this.getExchangeRate();
          this.closeDialog();
        })
        .catch((error) => {
          this.bLoading = false;
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    stopTimer() {
      clearTimeout(this.timerId);
      this.timerCount = 30;
    },
    createSubAccount(data) {
      const payload = {
        sBank_name: data.sBankName,
        sAccount_number: data.sAccountNumber,
        sRouting_number: data.sRoutingNumber,
        clabe: data.clabe,
        sBank_mxn_account: data.sBankMxnAccount
      };

      const config = {
        headers: {
          Authorization: `Bearer ${this.$store.state.sToken}`
        }
      };

      DB.post(
        `${URI}/api/v1/${this.selectLanguage}/sub-account/`,
        payload,
        config
      )
        .then((response) => {
          this.mixSuccess(response.data.message);
          this.getExchangeRate();
        })
        .catch((error) => {
          this.mixError(error.response.data.message, error.response.status);
        });
    }
  },
  computed: {
    selectLanguage: function () {
      return this.$store.state.language;
    }
  },
  watch: {
    selectLanguage: function () {
      if (this.selectLanguage) {
        this.getTemporalShoppingCart();
      }
    },
    timerCount: function (newValue) {
      if (newValue > 0) {
        this.timerId = setTimeout(() => {
          this.timerCount--;
        }, 1000);
      } else {
        this.getActualCurrency();
        this.timerCount = 30;
      }
    },
    "editableData.sProvider": function (newValue) {
      if (newValue === 0) {
        this.showCreateProvider = true;
      } else {
        this.showCreateProvider = false;
      }
    },
    $route(to, from) {}
  }
};
</script>

<style>
.container-profile .v-slide-group__content {
  margin-bottom: 4px !important;
}

.separator-line-tab {
  background: transparent linear-gradient(268deg, #ff8181 0%, #ffa77e 100%) 0%
    0% no-repeat padding-box;
  height: 40px;
  margin-left: 2px;
}

.tabs-profile {
  font-size: 14px;
  text-transform: initial;
  letter-spacing: 0px;
  text-align: center;
}

.v-tab--active {
  font-family: "montserrat-bold", sans-serif;
  color: #3d414a !important;
}

.v-tabs-items {
  background-color: transparent !important;
}

.theme--light.v-tabs .v-tab:focus::before,
.theme--light.v-tabs .v-tab--active:hover::before,
.theme--light.v-tabs .v-tab--active::before {
  opacity: 0;
}

.text-title {
  text-align: left;
  font-size: 35px;
  letter-spacing: 0px;
  color: #151d27;
  opacity: 1;
  margin-bottom: 0px;
}

.full-height {
  height: 100vh;
  width: 100%;
  overflow-y: auto;
}

.modal {
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 9999;
  background-color: white;
  border-width: 1px;
  border-color: #151d27;
}

.button-save:disabled {
  background: gray !important;
  cursor: not-allowed;
  pointer-events: visible;
}

.button-cancel {
  height: 40px !important;
  width: 45% !important;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #283c4d;
  border-radius: 10px;
  opacity: 1;
  text-align: center;
  font-size: 14px;
  letter-spacing: 0px;
  color: #283c4d;
  background-color: #ffffff !important;
  text-transform: initial;
}

.button-save {
  height: 40px !important;
  width: 45% !important;
  background: transparent linear-gradient(257deg, #ffc556 0%, #fffc81 100%) 0%
    0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
  text-align: center;
  font-size: 14px;
  letter-spacing: 0px;
  color: #283c4d;
  text-transform: initial;
}

.container-dialog {
  width: 100%;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #e5e5e5;
  border-radius: 6px;
  opacity: 1;
  padding: 20px;
  position: relative;
}

.text-title {
  text-align: center;
  letter-spacing: 0px;
  color: #283c4d;
  opacity: 1;
  font-size: 25px;
}

.button-add {
  height: 40px !important;
  background: transparent linear-gradient(258deg, #ffc556 0%, #fffc81 100%) 0%
    0% no-repeat padding-box;
  border-radius: 13px;
  opacity: 1;
  text-align: center;
  font-size: 14px;
  letter-spacing: 0px;
  color: #283c4d;
  text-transform: initial;
}

.bg-white {
  background-color: #000;
}

.greeting h1 {
  margin: 0;
  color: #434343;
  font-size: 2rem;
}

.greeting p {
  margin: 0;
  color: #5c5c5c;
  font-size: 1rem;
}

.exchange-rate-card {
  background-color: #ffe082;
  padding: 1rem;
}

.exchange-rate-content {
  margin-bottom: 1rem;
}

.exchange-rate-title {
  display: block;
  color: #434343;
  font-size: 1.25rem;
}

.exchange-rate-value {
  display: block;
  color: #434343;
  font-size: 1.5rem;
  font-weight: bold;
}

.exchange-rate-bonus {
  display: block;
  color: #434343;
  font-size: 1rem;
}

.balance-card {
  border: 1px solid #e0e0e0;
}

.price-info span {
  display: block;
  color: #5c5c5c;
  font-size: 0.875rem;
}
</style>
